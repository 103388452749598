@import 'bootstrap_lib/_variables';

//-------- THEME
$theme-highlight: #fff;
$theme-mediumlight: #aaa;
$theme-lowlight: #000;
$theme-link: #7fdbff;
$theme-product-container: none;
// $theme-product-container: rgba(#eeeeee, 0.2);

$theme-hero: url(https://keekmerch.com/resources/stores/tannhauserkrieg/images/hero.png);
$theme-background: none;

//------- FONTS
$font-family: 'Open Sans', Helvetica, sans-serif;
$font: normal 13px/18px $font-family;
$font-custom: 'Open Sans', sans-serif;
$font-icon: "FontAwesome";
$fa-css-prefix: icon !default;

//-------- BORDERS
$base-border-color: #d6d4d4;
$main_border: 1px solid #999;
$old_border: 1px solid $base-border-color;
$theme-border: 2px solid #999;
$dark-border-color: #333;
$med-border-color: #555;
$light-border-color: #fff;
$homepage-footer-border: #d9d9d9;

//--------- BACKGROUNDS
$base-box-bg: #fbfbfb;
$table-background: $base-box-bg;
$box-background: #111;
$dark-background: #333;
$body-background: $dark-background;
$light-background: #fff;
$homepage-footer-bg: #f2f2f2;
$nav-background: #000;

//--------- COLORS
$base-body-color: #eee;
$dark-body-color: #333;
$base-text-color: #222;
$grey-text-color: #999;
$med-text-color: #666;
$base-heading-color: #fff;
$base-title-color: $base-text-color;
$light-text-color: #fff;
// $link-color:						$link-color;
$link-hover-color: #fff;
$description-color: #ccc;
$main-white: $base-body-color;

//--------- Indents
$base-page-indent: $grid-gutter-width;
$base-box-padding: 14px 18px 13px;
$base-box-indent: 0 0 30px 0;

//---------- Product Elements
$product-name-color: #fff;
$product-name-hover-color: #515151;
$price-color: $dark-body-color;
$product-price-color: #000;
$product-reduced-price: #f00;
$product-special-price-color: #f13340;
$product-old-price-color: #6f6f6f;

//---------- Buttons
$button-text-color: $light-text-color;

//------ Buttons +/- ---
$button-pm-color: #c0c0c0;
$button-pm-hover-color: $base-text-color;

//------ Button Small --
$button-small-bg: #6f6f6f;
$button-small-hover-bg: #575757;

//-------Button exclusive-medium
$button-em-color: $base-text-color;

//---------- Product Listing
$base-listing-border-color: $base-border-color;
$base-listing-grid-padding: $base-box-padding;

//-------------- Grid/List vars
$GL-text-color: #808080;
$GL-title-color: $base-text-color;
$GL-icon-color: #e1e0e0;
$GL-icon-color-hover: $base-text-color;

//-------------- Pagination vars
$pagination-prevnext-color: #777676;
$pagination-bgcolor: $base-box-bg;
$pagination-hover-bg: #f6f6f6;

//---------- Product Info
$top-line-color: #c4c4c4;
$product-image-border: #dbdbdb;
$scroll-button-color: #c0c0c0;
$list-info-bg: #f6f6f6;
$scroll-button-hover-color: $base-text-color;
$tr-even-bg: #fdfdfd;

//sub heading (h2,h3) define
@mixin sub-heading {
	color: $base-heading-color;
	text-transform: uppercase;
	font-family: $font-custom;
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	padding: 14px 20px 17px;
	margin: 0 0 20px;
	position: relative;
	border: 1px solid $base-border-color;
	border-bottom: none;
	background: $base-box-bg;
}
